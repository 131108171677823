import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { SocketService } from '@app/shared/services/socket.service';

export const websocketDisconnectGuard: CanDeactivateFn<unknown> = () => {
    const socketService = inject(SocketService);
    socketService.socket.disconnect();

    return true;
};
