import { inject } from '@angular/core';
import { Router, CanActivateFn } from '@angular/router';
import { map, take } from 'rxjs';
import { AuthenticatedUserFacade } from '@app/store/authenticated-user';

export const userLoggedInGuard: CanActivateFn = () => {
    const authenticatedUserFacade = inject(AuthenticatedUserFacade);
    const router = inject(Router);

    return authenticatedUserFacade.loaded$.pipe(
        take(1),
        map((loaded) => (loaded === false ? router.createUrlTree(['/account']) : true)),
    );
};
