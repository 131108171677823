import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { filter, map, take, tap } from 'rxjs';
import { AuthenticatedUserFacade } from '@app/store/authenticated-user';

export const userPreloadGuard: CanActivateFn = () => {
    const authenticatedUserFacade = inject(AuthenticatedUserFacade);

    return authenticatedUserFacade.loaded$.pipe(
        tap((loaded) => {
            if (loaded === null) {
                authenticatedUserFacade.loadUserGracefull();
            }
        }),
        filter((loaded) => loaded !== null),
        take(1),
        map(() => true),
    );
};
