import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs';
import { PrivilegesService } from '../services/privileges.service';

export const hasPrivilegeGuard: CanActivateFn = (route) => {
    const router = inject(Router);
    const privilegesService = inject(PrivilegesService);

    const feature = <string>route.data['access'];
    return privilegesService.has$(feature).pipe(map((hasAccess) => hasAccess || router.createUrlTree(['/home'])));
};
