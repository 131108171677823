import { trigger, transition, animate, style } from '@angular/animations';

export const Scale = trigger('Scale', [
    transition(':enter', [
        style({
            opacity: 0.1,
            transform: 'scale(0.9)',
        }),
        animate(
            '0.3s ease-in-out',
            style({
                opacity: 1,
                transform: 'scale(1)',
            }),
        ),
    ]),
]);
