import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { SocketService } from '@app/shared/services/socket.service';

export const websocketConnectGuard: CanActivateFn = () => {
    const socketService = inject(SocketService);
    socketService.socket.connect();

    return true;
};
