import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { filter, map, take, tap } from 'rxjs';
import { PlansFacade } from '@app/store/plans';

export const plansPreloadGuard: CanActivateFn = () => {
    const plansFacade = inject(PlansFacade);

    return plansFacade.loaded$.pipe(
        tap((loaded) => {
            if (loaded !== true) {
                plansFacade.getPlans();
            }
        }),
        filter((loaded) => loaded === true),
        take(1),
        map(() => true),
    );
};
