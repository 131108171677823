import { getStatus, UserStatus, UserStatusDto } from './user-status.model';

interface Employee {
    id: string;
    firstname: string;
    lastname: string;
}

export interface Subscription {
    manuallyPay?: number;
    active?: {
        active: boolean | number;
        dateStart: number;
        dateEnd: number;
    };
    paid: number;
    cycle: boolean;
    isTrial: boolean;
    plan: string;
}

interface VideoCounts {
    videoCount: number;
    additionalVideoCount: number;
}

export interface UserDto {
    id: string;
    nickname: string;
    email: string;
    avatar: string | null;
    firstname: string;
    lastname: string;
    firstLogin: number;
    lastLogin: number;
    lastMessageTime?: number;
    status: UserStatusDto;
    subscription?: Subscription;
    neverPaid?: boolean;
    admin: Employee | null;
    psychologist: Employee | null;
    roles?: string[];
    shift?: string | null;
    address?: string;
    ip?: string | null;
    processingOfPersonalData?: boolean;
    emailNotification?: boolean;
    canUseTrial?: boolean;
    videoCounts?: VideoCounts | null;
    number?: number;
    room?: {
        id: string;
    };
}

export class User {
    id: string;
    nickname: string;
    email: string;
    avatar: string | null;
    firstname: string;
    lastname: string;
    firstLogin: number;
    lastLogin: number;
    lastMessageTime?: number;
    status: UserStatus;
    subscription?: Subscription;
    admin: Employee | null;
    neverPaid?: boolean;
    psychologist: Employee | null;
    roles: string[];
    shift?: string | null;
    address?: string;
    ip?: string | null;
    processingOfPersonalData?: boolean;
    emailNotification?: boolean;
    canUseTrial?: boolean;
    videoCounts?: VideoCounts | null;
    number?: number;
    room?: {
        id: string;
    };

    constructor(dto: UserDto) {
        this.id = dto.id;
        this.nickname = dto.nickname;
        this.email = dto.email;
        this.avatar = dto.avatar;
        this.firstname = dto.firstname;
        this.lastname = dto.lastname;
        this.firstLogin = dto.firstLogin;
        this.lastLogin = dto.lastLogin;
        this.subscription = dto.subscription;
        this.admin = dto.admin;
        this.neverPaid = dto.neverPaid;
        this.psychologist = dto.psychologist;
        this.roles = dto.roles ?? [];
        this.shift = dto.shift;
        this.address = dto.address;
        this.ip = dto.ip;
        this.processingOfPersonalData = dto.processingOfPersonalData;
        this.emailNotification = dto.emailNotification;
        this.canUseTrial = dto.canUseTrial;
        this.videoCounts = dto.videoCounts;
        this.number = dto.number;
        this.room = dto.room;
        this.lastMessageTime = dto.lastMessageTime ? dto.lastMessageTime * 1000 : undefined;
        this.status = getStatus(dto.status);
    }
}

export interface TypingResponse {
    from: string;
    room: string;
    to: string[];
    sentAt: number;
}

export interface Typing {
    user: string;
    typing: boolean;
}

export interface UnreadMessagesResponse {
    userFromId: string;
    unread: number;
}
