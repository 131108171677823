import { inject } from '@angular/core';
import { Router, CanActivateFn } from '@angular/router';
import { map, take } from 'rxjs';
import { AuthenticatedUserFacade } from '@app/store/authenticated-user';

export const neverPaidGuard: CanActivateFn = () => {
    const authenticatedUserFacade = inject(AuthenticatedUserFacade);
    const router = inject(Router);

    return authenticatedUserFacade.user$.pipe(
        take(1),
        map((user) => (user.neverPaid === true ? router.createUrlTree(['/register-payment']) : true)),
    );
};
